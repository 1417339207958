import React from 'react';
import values from 'lodash/values';
import omit from 'lodash/omit';
import get from 'lodash/get';
import has from 'lodash/has';
import findIndex from 'lodash/findIndex';
import set from 'lodash/set';
import cloneDeep from 'lodash/cloneDeep';
import { PrintLayout, withTabs } from '@skunexus/component-catalog';
import { PrintLayout as BatchingPrintLayout } from '@skunexus/fulfillment-batching-print';

import {
    SHIP_TITLE_PREFIX,
    SHIP_DOMAIN_NAME,
    SHIP_WORKFLOW_NAME,
    SHIP_BATCHING_ROUTE,
    SHIP_BATCH_PRINT_ROUTE,
    SHIP_GROUP_PICK_ROUTE,
    SHIP_DASHBOARD_ROUTE,
    SHIP_CANCEL_ROUTE,
    SHIP_PACK_ROUTE,
    SHIP_PICK_ROUTE,
    SHIP_PRINT_LOGS_ROUTE,
    SHIP_PRINTED_ROUTE,
    SHIP_DETAILS_ROUTE,
    SHIP_GROUP_PICK_DETAILS_ROUTE,
    SHIP_PICKING_ROUTE,
    SHIP_GROUP_PICKING_ROUTE,
    SHIP_PACKING_ROUTE,
    ORDER_DETAILS_ROUTE,
    PRODUCTS_DETAILS_ROUTE,
    USERS_DETAILS_ROUTE,
    SHIPMENT_DETAILS_ROUTE,
} from './consts';
import { filterAccessibleItems } from '../../../utils';
import UserModel from '../../../UserModel';
import MainTabs from './components/FulfillmentMainTabsLayout';

export default (app) => {
    const batchEnabled = get(app.general.getItem('modules'), 'fulfillment.batchEnable', false);

    const shipMainCustomGridSections = (config) => {
        const newConfig = cloneDeep(config);
        if (batchEnabled) {
            newConfig.sections = {
                ...newConfig.sections,
                batched: {
                    title: 'Batching',
                    path: SHIP_BATCHING_ROUTE,
                    status: ['batched', 'pick', 'printable'],
                    order: 10,
                },
                printed: {
                    title: 'Printed',
                    path: SHIP_PRINTED_ROUTE,
                    order: 20,
                },
            };
            newConfig.sections = omit(newConfig.sections, ['pick', 'pack']);
            return newConfig;
        }
        return config;
    };

    const routes = {
        fulfillmentShipMain: {
            path: app.fulfillmentShipTabs.getItem('main').link,
            title: app.fulfillmentShipTabs.getItem('main').title,
            permission: app.fulfillmentShipTabs.getItem('main').permission,
            props: () => ({
                customGridSections: shipMainCustomGridSections,
                detailsPageRoute: SHIP_DETAILS_ROUTE,
                pickPageRoute: SHIP_PICKING_ROUTE,
                packPageRoute: SHIP_PACKING_ROUTE,
                printLogsPageRoute: SHIP_PRINT_LOGS_ROUTE,
            }),
            loader: () => import('@skunexus/ship-main'),
            render: (Component) => (props) =>
                (
                    <MainTabs showCartFilter={false} showAssignUserFilter={false} {...props} name="fulfillmentShipMain">
                        <Component {...props} />
                    </MainTabs>
                ),
        },
        fulfillmentShipPrintLogs: {
            path: app.fulfillmentShipTabs.getItem('printLogs').link,
            title: app.fulfillmentShipTabs.getItem('printLogs').title,
            permission: app.fulfillmentShipTabs.getItem('printLogs').permission,
            props: () => ({
                shipmentDetailsPageRoute: SHIPMENT_DETAILS_ROUTE,
            }),
            loader: () => import('@skunexus/fulfillment-print-logs'),
            render: (Component) => (props) =>
                (
                    <MainTabs
                        showCartFilter={false}
                        showAssignUserFilter={false}
                        {...props}
                        name="fulfillmentShipPrintLogs"
                    >
                        <Component {...props} />
                    </MainTabs>
                ),
        },
        fulfillmentShipDispatched: {
            path: app.fulfillmentShipTabs.getItem('dispatched').link,
            title: app.fulfillmentShipTabs.getItem('dispatched').title,
            permission: app.fulfillmentShipTabs.getItem('dispatched').permission,
            props: () => ({
                orderDetailsPageRoute: ORDER_DETAILS_ROUTE,
                detailsPageRoute: SHIPMENT_DETAILS_ROUTE,
                printLogsPageRoute: SHIP_PRINT_LOGS_ROUTE,
            }),
            loader: () => import('@skunexus/fulfillment-dispatched'),
            render: (Component) => (props) =>
                (
                    <MainTabs showCartFilter={false} {...props} name="fulfillmentShipDispatched">
                        <Component {...props} />
                    </MainTabs>
                ),
        },
        fulfillmentShipFulfilled: {
            path: app.fulfillmentShipTabs.getItem('finalized').link,
            title: app.fulfillmentShipTabs.getItem('finalized').title,
            permission: app.fulfillmentShipTabs.getItem('finalized').permission,
            props: () => ({
                orderDetailsPageRoute: ORDER_DETAILS_ROUTE,
                detailsPageRoute: SHIP_DETAILS_ROUTE,
            }),
            loader: () => import('@skunexus/fulfillment-fulfilled'),
            render: (Component) => (props) =>
                (
                    <MainTabs showCartFilter={false} {...props} name="fulfillmentShipFulfilled">
                        <Component {...props} />
                    </MainTabs>
                ),
        },
        fulfillmentAll: {
            path: app.fulfillmentShipTabs.getItem('all').link,
            title: app.fulfillmentShipTabs.getItem('all').title,
            permission: app.fulfillmentShipTabs.getItem('all').permission,
            props: () => ({
                shipmentDetailsPageRoute: SHIPMENT_DETAILS_ROUTE,
                orderDetailsRoute: ORDER_DETAILS_ROUTE,
            }),
            loader: () => import('@skunexus/fulfillment-all'),
            render: (Component) => (props) =>
                (
                    <MainTabs showCartFilter={false} showAssignUserFilter={false} {...props} name="fulfillmentAll">
                        <Component {...props} />
                    </MainTabs>
                ),
        },
        fulfillmentShipCancelled: {
            path: app.fulfillmentShipTabs.getItem('cancelled').link,
            title: app.fulfillmentShipTabs.getItem('cancelled').title,
            permission: app.fulfillmentShipTabs.getItem('cancelled').permission,
            props: () => ({
                orderDetailsPageRoute: ORDER_DETAILS_ROUTE,
                detailsPageRoute: SHIP_DETAILS_ROUTE,
            }),
            loader: () => import('@skunexus/fulfillment-cancelled'),
            render: (Component) => (props) =>
                (
                    <MainTabs {...props} name="fulfillmentShipCancelled">
                        <Component {...props} />
                    </MainTabs>
                ),
        },
        fulfillmentHospital: {
            path: app.fulfillmentShipTabs.getItem('hospital').link,
            title: app.fulfillmentShipTabs.getItem('hospital').title,
            permission: app.fulfillmentShipTabs.getItem('hospital').permission,
            props: () => ({
                orderDetailsPageRoute: ORDER_DETAILS_ROUTE,
                detailsPageRoute: SHIP_DETAILS_ROUTE,
            }),
            loader: () => import('@skunexus/fulfillment-hospital'),
            render: (Component) => (props) =>
                (
                    <MainTabs {...props} name="fulfillmentHospital">
                        <Component {...props} />
                    </MainTabs>
                ),
        },
        fulfillmentShipDetails: {
            path: `${SHIP_DETAILS_ROUTE}/:id`,
            title: `${SHIP_TITLE_PREFIX} > Details`,
            props: () => ({
                orderDetailsPageRoute: ORDER_DETAILS_ROUTE,
                pickingPageRoute: SHIP_PICKING_ROUTE,
                packingPageRoute: SHIP_PACKING_ROUTE,
                productDetailsPageRoute: PRODUCTS_DETAILS_ROUTE,
                detailsPageRoute: SHIP_DETAILS_ROUTE,
                shipmentDetailsPageRoute: SHIPMENT_DETAILS_ROUTE,
                currentUserId: UserModel.getUserId(),
            }),
            permission: ['fulfillments.picking', 'fulfillments.packing'],
            loader: () => import('@skunexus/fulfillment-details'),
        },
        fulfillmentShipDetailsPrint: {
            path: `${SHIP_DETAILS_ROUTE}/print/:id`,
            title: `${SHIP_TITLE_PREFIX} > Details > Print Preview`,
            props: () => ({
                orderDetailsPageRoute: ORDER_DETAILS_ROUTE,
                pickingPageRoute: SHIP_PICKING_ROUTE,
                packingPageRoute: SHIP_PACKING_ROUTE,
                productDetailsPageRoute: PRODUCTS_DETAILS_ROUTE,
                detailsPageRoute: SHIP_DETAILS_ROUTE,
                shipmentDetailsPageRoute: SHIPMENT_DETAILS_ROUTE,
                currentUserId: UserModel.getUserId(),
            }),
            layout: PrintLayout,
            permission: ['fulfillments.show'],
            loader: () => import('@skunexus/fulfillment-details'),
        },
        fulfillmentShipPicking: {
            path: `${SHIP_PICKING_ROUTE}/:id`,
            title: `${SHIP_TITLE_PREFIX} > Picking`,
            permission: ['fulfillments.picking'],
            props: () => ({
                orderDetailsPageRoute: ORDER_DETAILS_ROUTE,
                pickingPageRoute: SHIP_PICKING_ROUTE,
                packingPageRoute: SHIP_PACKING_ROUTE,
                pickPageRoute: SHIP_PICK_ROUTE,
                cancelPageRoute: SHIP_CANCEL_ROUTE,
                productDetailsPageRoute: PRODUCTS_DETAILS_ROUTE,
                detailsPageRoute: SHIP_DETAILS_ROUTE,
                shipmentDetailsPageRoute: SHIPMENT_DETAILS_ROUTE,
                userDetailsPageRoute: USERS_DETAILS_ROUTE,
                currentUserId: UserModel.getUserId(),
                showItemsImages: true,
            }),
            loader: () => import('@skunexus/fulfillment-picking'),
        },
        fulfillmentCancel: {
            path: `${SHIP_CANCEL_ROUTE}/:id`,
            title: `${SHIP_TITLE_PREFIX} > Cancel`,
            permission: ['fulfillments.index'],
            props: () => ({
                domain: SHIP_DOMAIN_NAME,
                packPageRoute: SHIP_PACK_ROUTE,
                packingPageRoute: SHIP_PACKING_ROUTE,
                pickPageRoute: SHIP_PICK_ROUTE,
                pickingPageRoute: SHIP_PICKING_ROUTE,
                dashboardPageRoute: SHIP_DASHBOARD_ROUTE,
                orderDetailsPageRoute: ORDER_DETAILS_ROUTE,
                productDetailsPageRoute: PRODUCTS_DETAILS_ROUTE,
                showItemsImages: true,
            }),
            loader: () => import('@skunexus/fulfillment-cancel'),
        },
        fulfillmentShipGroupPicking: {
            path: `${SHIP_GROUP_PICKING_ROUTE}/:id`,
            title: `${SHIP_TITLE_PREFIX} > Group Picking`,
            permission: ['fulfillments.picking'],
            props: () => ({
                detailsPageRoute: SHIP_GROUP_PICK_DETAILS_ROUTE,
                printPageRoute: `${SHIP_GROUP_PICK_DETAILS_ROUTE}/print`,
                packPageRoute: SHIP_PACK_ROUTE,
                groupPickPageRoute: SHIP_GROUP_PICK_ROUTE,
                productDetailsRoute: PRODUCTS_DETAILS_ROUTE,
                userDetailsPageRoute: USERS_DETAILS_ROUTE,
            }),
            loader: () => import('@skunexus/fulfillment-group-picking'),
        },
        fulfillmentShipGroupPickDetails: {
            path: `${SHIP_GROUP_PICK_DETAILS_ROUTE}/:id`,
            title: `${SHIP_TITLE_PREFIX} > Group Pick Details`,
            permission: ['fulfillments.picking'],
            props: () => ({
                //fulfillmentDetailsPageRoute: SHIP_DETAILS_ROUTE,
                pickingPageRoute: SHIP_GROUP_PICKING_ROUTE,
                productDetailsPageRoute: PRODUCTS_DETAILS_ROUTE,
                currentUserId: UserModel.getUserId(),
            }),
            loader: () => import('@skunexus/fulfillment-group-pick-details'),
        },
        fulfillmentShipPacking: {
            path: `${SHIP_PACKING_ROUTE}/:id`,
            title: `${SHIP_TITLE_PREFIX} > Packing`,
            permission: ['fulfillments.packing'],
            props: () => ({
                productDetailsPageRoute: PRODUCTS_DETAILS_ROUTE,
                orderDetailsPageRoute: ORDER_DETAILS_ROUTE,
                pickingPageRoute: SHIP_PICKING_ROUTE,
                packingPageRoute: SHIP_PACKING_ROUTE,
                packPageRoute: SHIP_PACK_ROUTE,
                detailsPageRoute: SHIP_DETAILS_ROUTE,
                shipmentDetailsPageRoute: SHIPMENT_DETAILS_ROUTE,
                currentUserId: UserModel.getUserId(),
                showItemsImages: true,
            }),
            loader: () => import('@skunexus/fulfillment-packing'),
        },
        fulfillmentWavesSettings: {
            path: app.settingsFulfillmentTabs.getItem('waves').link,
            title: app.settingsFulfillmentTabs.getItem('waves').label,
            permission: app.settingsFulfillmentTabs.getItem('waves').permission,
            props: () => ({}),
            loader: () => import('@skunexus/waves'),
            render: (Component) =>
                withTabs(filterAccessibleItems(values(app.settingsTabs.getAll())))(
                    withTabs(filterAccessibleItems(values(app.settingsFulfillmentTabs.getAll())), { sub: true })(
                        Component
                    )
                ),
        },
    };

    if (app.fulfillmentShipTabs.getItem('pick')) {
        routes.fulfillmentShipPick = {
            path: app.fulfillmentShipTabs.getItem('pick').link,
            title: app.fulfillmentShipTabs.getItem('pick').title,
            permission: app.fulfillmentShipTabs.getItem('pick').permission,
            props: () => ({
                orderDetailsPageRoute: ORDER_DETAILS_ROUTE,
                detailsPageRoute: SHIP_PICKING_ROUTE,
                groupPageRoute: SHIP_GROUP_PICKING_ROUTE,
                currentUserId: UserModel.getUserId(),
            }),
            loader: () => import('@skunexus/fulfillment-pick'),
            render: (Component) => (props) =>
                (
                    <MainTabs {...props} name="fulfillmentShipPick">
                        <Component {...props} />
                    </MainTabs>
                ),
        };
    }

    if (app.fulfillmentShipTabs.getItem('groupPick')) {
        routes.fulfillmentShipGroupPick = {
            path: app.fulfillmentShipTabs.getItem('groupPick').link,
            title: app.fulfillmentShipTabs.getItem('groupPick').title,
            permission: app.fulfillmentShipTabs.getItem('groupPick').permission,
            props: () => ({
                detailsPageRoute: SHIP_GROUP_PICK_DETAILS_ROUTE,
                currentUserId: UserModel.getUserId(),
            }),
            loader: () => import('@skunexus/fulfillment-group-pick'),
            render: (Component) => (props) =>
                (
                    <MainTabs {...props} name="fulfillmentShipGroupPick">
                        <Component {...props} />
                    </MainTabs>
                ),
        };
    }

    if (app.fulfillmentShipTabs.getItem('pack')) {
        routes.fulfillmentShipPack = {
            path: app.fulfillmentShipTabs.getItem('pack').link,
            title: app.fulfillmentShipTabs.getItem('pack').title,
            permission: app.fulfillmentShipTabs.getItem('pack').permission,
            props: () => ({
                orderDetailsPageRoute: ORDER_DETAILS_ROUTE,
                detailsPageRoute: SHIP_PACKING_ROUTE,
                packingPageRoute: SHIP_PACKING_ROUTE,
                printLogsPageRoute: SHIP_PRINT_LOGS_ROUTE,
                currentUserId: UserModel.getUserId(),
            }),
            loader: () => import('@skunexus/fulfillment-pack'),
            render: (Component) => (props) =>
                (
                    <MainTabs {...props} name="fulfillmentShipPack">
                        <Component
                            {...props}
                            onFailedToPurchaseLabel={(fulfillmentId, barcode) =>
                                props.history.push(`/fulfillment-ship/packing/${fulfillmentId}?barcode=${barcode}`)
                            }
                        />
                    </MainTabs>
                ),
        };
    }

    if (app.fulfillmentShipTabs.getItem('waves')) {
        const customWaveGridConfig = (data) => {
            if (!has(data.config.filtering, 'state')) {
                set(data, 'config.filtering.state', { operator: 'in', value: ['not_picked', 'partial_picked'] });
            }
            return {
                ...data,
            };
        };

        routes.fulfillmentWaves = {
            path: app.fulfillmentWavesTabs.getItem('waves').link,
            title: app.fulfillmentWavesTabs.getItem('waves').title,
            permission: app.fulfillmentWavesTabs.getItem('waves').permission,
            props: () => ({
                detailsPageRoute: SHIP_GROUP_PICK_DETAILS_ROUTE,
                customGridConfig: customWaveGridConfig,
            }),
            loader: () => import('@skunexus/fulfillment-waves'),
            render: (Component) => (props) =>
                (
                    <MainTabs {...props} name="fulfillmentWaves">
                        {withTabs(filterAccessibleItems(values(app.fulfillmentWavesTabs.getAll())), { sub: true })(
                            Component
                        )(props)}
                    </MainTabs>
                ),
        };

        const customWaveHistoryGridData = (data) => {
            const stateColIndex = findIndex(get(data, 'columns', []), ['field', 'state']);

            if (stateColIndex >= 0) {
                set(data, `columns[${stateColIndex}].isFilterable`, false);
                set(data, `columns[${stateColIndex}].defaultFilter`, ['picked']);
                set(data, `columns[${stateColIndex}].options`, [{ label: 'Picked', value: 'picked' }]);
            }

            return {
                ...data,
            };
        };

        const customWaveHistoryGridConfig = (data) => {
            set(data, 'config.filtering.state', { operator: 'in', value: ['picked'] });
            return {
                ...data,
            };
        };

        routes.fulfillmentWavesHistory = {
            path: app.fulfillmentWavesTabs.getItem('wavesHistory').link,
            title: app.fulfillmentWavesTabs.getItem('wavesHistory').title,
            permission: app.fulfillmentWavesTabs.getItem('wavesHistory').permission,
            props: () => ({
                detailsPageRoute: SHIP_GROUP_PICK_DETAILS_ROUTE,
                gridConfig: {
                    id: 'fulfillment-wave-history-grid',
                    domain: SHIP_DOMAIN_NAME,
                    workflow: SHIP_WORKFLOW_NAME,
                },
                releaseWaveEnabled: false,
                customGridData: customWaveHistoryGridData,
                customGridConfig: customWaveHistoryGridConfig,
            }),
            loader: () => import('@skunexus/fulfillment-waves'),
            render: (Component) => (props) =>
                (
                    <MainTabs {...props} name="fulfillmentWavesHistory">
                        {withTabs(filterAccessibleItems(values(app.fulfillmentWavesTabs.getAll())), { sub: true })(
                            Component
                        )(props)}
                    </MainTabs>
                ),
        };
    }

    if (app.fulfillmentShipTabs.getItem('batching')) {
        routes.fulfillmentShipBatching = {
            path: app.fulfillmentShipTabs.getItem('batching').link,
            title: app.fulfillmentShipTabs.getItem('batching').title,
            permission: app.fulfillmentShipTabs.getItem('batching').permission,
            props: () => ({
                printRoute: SHIP_BATCH_PRINT_ROUTE,
                fulfillmentDetailsRoute: SHIP_DETAILS_ROUTE,
                orderDetailsRoute: ORDER_DETAILS_ROUTE,
            }),
            loader: () => import('@skunexus/fulfillment-batching'),
            render: (Component) => (props) =>
                (
                    <MainTabs
                        showCartFilter={false}
                        showAssignUserFilter={false}
                        {...props}
                        name="fulfillmentShipBatching"
                    >
                        <Component {...props} />
                    </MainTabs>
                ),
        };
        routes.fulfillmentShipBatchingPrint = {
            path: '/fulfillment-ship/batch-print',
            title: `${SHIP_TITLE_PREFIX} > Batching`,
            permission: app.fulfillmentShipTabs.getItem('batching').permission,
            props: () => ({
                returnRoute: SHIP_BATCHING_ROUTE,
            }),
            layout: BatchingPrintLayout,
            loader: () => import('@skunexus/fulfillment-batching-print'),
        };
    }

    if (app.fulfillmentShipTabs.getItem('printed')) {
        routes.fulfillmentShipPrinted = {
            path: app.fulfillmentShipTabs.getItem('printed').link,
            title: app.fulfillmentShipTabs.getItem('printed').title,
            permission: app.fulfillmentShipTabs.getItem('printed').permission,
            props: () => ({
                shipmentDetailsPageRoute: SHIPMENT_DETAILS_ROUTE,
                orderDetailsPageRoute: ORDER_DETAILS_ROUTE,
            }),
            loader: () => import('@skunexus/fulfillment-printed'),
            render: (Component) => (props) =>
                (
                    <MainTabs
                        showCartFilter={false}
                        showAssignUserFilter={false}
                        {...props}
                        name="fulfillmentShipPrinted"
                    >
                        <Component {...props} />
                    </MainTabs>
                ),
        };
    }

    return routes;
};
