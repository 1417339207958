import { createRoutine } from '@skunexus/utils';

import * as warehouses from './warehouses/actions';
import * as exportModal from './exportModal/actions';


const prefix = 'EXPORT_STOCK';

const exportStock = createRoutine(`${prefix}/EXPORT_STOCK`);

export default {
    warehouses,
    exportModal,
    exportStock
};
