export default () => {
    const routers = {
        orders: {
            exact: true,
            path: '/orders',
            title: 'Orders',
            permission: ['orders.index'],
            loader: () => import('@skunexus/orders'),
            props: () => ({
                detailsPageRoute: '/orders',
            }),
        },
        orderDetails: {
            exact: true,
            path: '/orders/:id',
            title: 'Order Details',
            permission: ['orders.show'],
            loader: () => import('@skunexus/order-details'),
            props: () => ({
                productDetailsPageRoute: '/products/overview',
                shipmentDetailsPageRoute: '/shipment',
                fulfillmentDetailsPageRouteShip: '/fulfillment-ship/details',
                fulfillmentDetailsPageRoutePickup: '/fulfillment-pickup/details',
                fulfillmentGroupDetailsPageRouteShip: '/fulfillment-ship/group-pick-details',
                fulfillmentGroupDetailsPageRoutePickup: '/fulfillment-pickup/group-pick-details',
                vendorDetailsPageRoute: '/vendors',
                purchaseOrderRoute: '/purchase-orders/overview',
            }),
        },
    };

    return routers;
};
