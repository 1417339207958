import React, { useEffect, useState, Fragment } from 'react';
import PropTypes from 'prop-types';

import get from 'lodash/get';

import Details from './components/Details';
import Items from './components/Items';

import { getCustomFieldValue } from './utils';
import styles from './styles.module.css';

const ShipmentDetails = ({ details, onLoaded }) => {
    const shipmentLabelUrl = get(details, 'relatedShipment.label_url', '');
    const messageRecipient = getCustomFieldValue(details.relatedOrder, 'gift_recipient', '');
    const messageSender =
        getCustomFieldValue(details.relatedOrder, 'gift_sender', '') ||
        `${getCustomFieldValue(details.relatedOrder, 'customer_first_name', '')} ${getCustomFieldValue(
            details.relatedOrder,
            'customer_last_name',
            ''
        )}`;

    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        return () => {
            setLoaded(false);
        };
    }, []);

    useEffect(() => {
        if (loaded) {
            onLoaded();
        }
    }, [loaded]);

    const handleLabelLoaded = () => {
        setLoaded(true);
    };

    const loadLabel = (input) => {
        if (!input) return;
        const img = input;
        img.onload = handleLabelLoaded;
        img.onerror = handleLabelLoaded;
        if (img.complete) {
            handleLabelLoaded();
        }
    };

    return (
        <Fragment>
            <div className={styles.page}>
                <div className={styles.firstWing}>
                    <div className={styles.wingContainer}>
                        <Details details={details} />
                        <Items details={details} />
                    </div>
                </div>
                <div className={styles.secondWing}>
                    <div className={styles.wingContainer}>
                        {shipmentLabelUrl ? (
                            <img
                                ref={loadLabel}
                                src={shipmentLabelUrl}
                                className={styles.printLabelImage}
                                alt="shipping label"
                            />
                        ) : (
                            <div className={styles.noShipmentLabelMessage}>
                                <div>Shipping Label Missing</div>
                                <div>Tracking Code: {get(details, 'relatedShipment.tracking_code', '')}</div>
                            </div>
                        )}
                    </div>
                </div>
                <div className={styles.thirdWing}>
                    <div className={styles.wingContainer}>
                        <div className={styles.thirdWingContent}>
                            <div className={styles.messageContent}>
                                <div className={styles.messageInnerContent}>
                                    <div className={styles.message}>
                                        {messageRecipient && <p>To: {messageRecipient}</p>}
                                        <p>
                                            {getCustomFieldValue(
                                                details.relatedOrder,
                                                'gift_message',
                                                'Thank you for your order!'
                                            )}
                                        </p>
                                        {messageSender && <p>From: {messageSender}</p>}
                                    </div>
                                    <div className={styles.orderNo}>
                                        Order Number: {get(details, 'relatedOrder.label', '')}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className={styles.page}>
                <div className={styles.firstWing}>
                    <div className={styles.wingContainer}></div>
                </div>
                <div className={styles.secondWing}>
                    <div className={styles.wingContainer}></div>
                </div>
                <div className={styles.thirdWing}>
                    <div className={styles.wingContainer}></div>
                </div>
            </div> */}
        </Fragment>
    );
};

ShipmentDetails.propTypes = {
    details: PropTypes.object,
    onLoaded: PropTypes.func,
};

ShipmentDetails.defaultProps = {
    details: {},
    onLoaded: () => {},
};

export default ShipmentDetails;
