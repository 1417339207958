import React from 'react';
import values from 'lodash/values';
import { withTabs } from '@skunexus/component-catalog';
import { filterAccessibleItems } from '../../../utils';

export default (app) => {
    const routers = {
        orderDecisionSettings: {
            path: app.settingsOrderTabs.getItem('orderDecisionSettings').link,
            title: app.settingsOrderTabs.getItem('orderDecisionSettings').title,
            permission: app.settingsOrderTabs.getItem('orderDecisionSettings').permission,
            props: () => {},
            loader: () => import('@skunexus/order-decision-settings'),
            render: (Component) =>
                withTabs(filterAccessibleItems(values(app.settingsTabs.getAll())))(
                    withTabs(filterAccessibleItems(values(app.settingsOrderTabs.getAll())), { sub: true })((props) => (
                        <Component {...props} />
                    ))
                ),
        },
        orderDecisionRuleSettings: {
            path: app.settingsOrderTabs.getItem('orderDecisionRuleSettings').link,
            title: app.settingsOrderTabs.getItem('orderDecisionRuleSettings').title,
            permission: app.settingsOrderTabs.getItem('orderDecisionRuleSettings').permission,
            props: () => {},
            loader: () => import('@skunexus/order-decision-rules-settings'),
            render: (Component) =>
                withTabs(filterAccessibleItems(values(app.settingsTabs.getAll())))(
                    withTabs(filterAccessibleItems(values(app.settingsOrderTabs.getAll())), { sub: true })((props) => (
                        <Component {...props} />
                    ))
                ),
        },
    };

    return routers;
};
