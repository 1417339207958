import { all, call, takeEvery, put } from 'redux-saga/effects';
import { ajaxExceptionMessage } from '@skunexus/utils';
import get from 'lodash/get';

import { fetchRows } from './actions';
import * as API from '../../api';

function* onFetchRows({ payload }) {
    try {
        yield put(fetchRows.REQUEST(payload));

        const response = yield call(API.fetchWarehouses, payload);
        const data = get(response, 'data.data.warehouse.grid', null);

        if (!data) {
            throw new Error('Something went wrong with warehouses load.');
        }

        yield put(fetchRows.SUCCESS(data));
    } catch (e) {
        yield put(fetchRows.FAILURE(ajaxExceptionMessage(e)));
    } finally {
        yield put(fetchRows.FULFILL());
    }
}

export default function* warehousesSagas() {
    yield all([takeEvery(fetchRows.TRIGGER, onFetchRows)]);
}
