import axios from 'axios';
import { constants } from '@skunexus/utils';

const API_PATH = constants.CONFIG.API_PATH;

export function holdOrder({ orderId }) {
    return axios.put(`${API_PATH}/orders/${orderId}/hold`);
}

export function unholdOrder({ orderId }) {
    return axios.put(`${API_PATH}/orders/${orderId}/unhold`);
}
