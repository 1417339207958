export default (app) => ({
    overview: {
        id: 'Overview',
        label: 'Overview',
        title: 'Product Details Overview',
        link: '/products/overview/:id',
        permission: 'products.show',
        order: 10,
    },
    stock: {
        id: 'Stock',
        label: 'Stock',
        title: 'Product Details Stock',
        link: '/products/stock/:id',
        order: 20,
    },
    allocations: {
        id: 'Allocations',
        label: 'Allocations',
        title: 'Product Details Allocations',
        link: '/products/allocations/:id',
        permission: 'inventory - allocations.index',
        order: 30,
    },
    adjustments: {
        id: 'Adjustments',
        label: 'Adjustments',
        title: 'Product Details Adjustments',
        link: '/products/adjustments/:id',
        permission: 'inventory - adjustments.index',
        order: 40,
    },
    barcodes: {
        id: 'Barcodes',
        label: 'Barcodes',
        title: 'Product Details Barcodes',
        link: '/products/barcodes/:id',
        permission: 'products - barcodes.index',
        order: 50,
    },
    reservations: {
        id: 'Reservations',
        label: 'Reservations',
        title: 'Product Details Reservations',
        link: '/products/reservations/:id',
        permission: 'inventory - reservations.index',
        order: 60,
    },
    kitting: {
        id: 'Kitting',
        label: 'Kitting',
        title: 'Product Details Kitting',
        link: '/products/kitting/:id',
        permission: 'products - kits.index',
        order: 70,
    },
    media: {
        id: 'Media',
        label: 'Media',
        title: 'Product Details Media',
        link: '/products/media/:id',
        order: 80,
    },
});
