import axios from 'axios';
import { constants, gql, createBlobFromResponse } from '@skunexus/utils';

const API_PATH = constants.CONFIG.API_PATH;

export function exportStock({ data }) {
    return axios
        .post(
            `${API_PATH}/query`,
            {
                query: `
                    query {
                        stock {
                        grid: stocksGrid(limit: {size: 9999}, filter: {location: {warehouse_key: {operator: eq, value: "${data.id}"}}}) {
                            rows {
                                product {
                                    sku
                                    label
                                    name
                                    weight
                                    image {
                                    url
                                    type
                                    }
                                }
                                qty
                                qty_reserved
                                qty_available
                                location {
                                    warehouse {
                                    label
                                    }
                                }
                            }
                        }
                        }
                    }
                    `,
            },
            {
                headers: {
                    accept: 'text/csv',
                },
            }
        )
        .then(createBlobFromResponse({ fileName: 'export-stock.csv' }));
}

export function fetchWarehouses() {
    return gql.getGraphQLGrid({
        url: `${API_PATH}/query`,
        config: {
            domain: 'warehouse',
        },
        gridQueryPart: `
            grid(sort: {name: ASC}, filter: { status: { operator: eq, value: 1 }}, limit:{size: 999, page:0}){
                rows {
                    value: id
                    label
                }
            }
            `,
    });
}
