import values from 'lodash/values';
import { PrintLayout, withTabs } from '@skunexus/component-catalog';
import { filterAccessibleItems } from '../../../utils';

export default (app) => ({
    shipment: {
        path: '/shipment/:id',
        title: 'Shipment',
        permission: ['fulfillments.show'],
        props: () => ({
            fulfillmentDashboardPageRoute: '/fulfillment-ship/main',
            fulfillmentDetailsPageRoute: '/fulfillment-ship/details',
            orderDetailsPageRoute: '/orders',
            productDetailsPageRoute: '/products/overview',
        }),
        loader: () => import('@skunexus/shipment'),
    },
    shipmentPrint: {
        path: '/shipment/print/:id',
        title: 'Shipment fromFulfillmentPrintLogs',
        permission: ['fulfillments.show'],
        layout: PrintLayout,
        loader: () => import('@skunexus/shipment'),
    },
    shipmentResend: {
        path: '/shipment/resend/:id',
        title: 'Shipment Resend',
        permission: ['other.all'],
        props: () => ({
            fulfillmentDetailsPageRoute: '/fulfillment-ship/details',
            orderDetailsPageRoute: '/orders',
            productDetailsPageRoute: '/products/overview',
        }),
        loader: () => import('@skunexus/resend'),
    },
    classCodes: {
        path: app.settingsShippingTabs.getItem('classCodes').link,
        title: app.settingsShippingTabs.getItem('classCodes').label,
        permission: app.settingsShippingTabs.getItem('classCodes').permission,
        props: () => ({
            formPageRoute: '/settings/shipping-options/class-codes-form',
        }),
        loader: () => import('@skunexus/class-codes'),
        render: (Component) =>
            withTabs(filterAccessibleItems(values(app.settingsTabs.getAll())))(
                withTabs(filterAccessibleItems(values(app.settingsShippingTabs.getAll())), { sub: true })(Component)
            ),
    },
    classCodesForm: {
        path: '/settings/shipping-options/class-codes-form/:id?',
        title: 'Class Codes Form',
        props: () => ({
            indexPageRoute: '/settings/shipping-options/class-codes',
            detailsPageRoute: '/settings/shipping-options/class-codes-form',
        }),
        loader: () => import('@skunexus/class-codes-form'),
    },
    shipingOptionsMapper: {
        path: app.settingsShippingTabs.getItem('optionMappers').link,
        title: app.settingsShippingTabs.getItem('optionMappers').label,
        permission: app.settingsShippingTabs.getItem('optionMappers').permission,
        props: () => ({
            detailsPageRoute: '/settings/shipping-options/mapper',
        }),
        loader: () => import('@skunexus/settings-so-mappers'),
        render: (Component) =>
            withTabs(filterAccessibleItems(values(app.settingsTabs.getAll())))(
                withTabs(filterAccessibleItems(values(app.settingsShippingTabs.getAll())), { sub: true })(Component)
            ),
    },
    shipingOptionsMapperDetails: {
        path: '/settings/shipping-options/mapper/:id',
        title: 'Shiping Options Mapper Details',
        loader: () => import('@skunexus/mapper-details'),
    },
    carrierForm: {
        path: '/settings/shipping-options/carriers/carrier-form/:id?',
        title: 'Carrier Form',
        props: () => ({
            indexPageRoute: '/settings/shipping-options/carriers',
        }),
        loader: () => import('@skunexus/carrier-form'),
    },
    carrierServices: {
        path: app.settingsShippingTabs.getItem('carriers').link,
        title: app.settingsShippingTabs.getItem('carriers').label,
        permission: app.settingsShippingTabs.getItem('carriers').permission,
        props: () => ({
            formPageRoute: '/settings/shipping-options/carriers/carrier-form',
        }),
        loader: () => import('@skunexus/carrier-grid'),
        render: (Component) =>
            withTabs(filterAccessibleItems(values(app.settingsTabs.getAll())))(
                withTabs(filterAccessibleItems(values(app.settingsShippingTabs.getAll())), { sub: true })(Component)
            ),
    },
    parcels: {
        path: app.settingsShippingTabs.getItem('parcels').link,
        title: app.settingsShippingTabs.getItem('parcels').label,
        permission: app.settingsShippingTabs.getItem('parcels').permission,
        props: () => ({
            formPageRoute: '/settings/shipping-options/parcels/parcel-form',
        }),
        loader: () => import('@skunexus/parcels'),
        render: (Component) =>
            withTabs(filterAccessibleItems(values(app.settingsTabs.getAll())))(
                withTabs(filterAccessibleItems(values(app.settingsShippingTabs.getAll())), { sub: true })(Component)
            ),
    },
    parcelsForm: {
        path: '/settings/shipping-options/parcels/parcel-form/:id?',
        title: 'Parcel Form',
        props: () => ({
            indexPageRoute: '/settings/shipping-options/parcels',
        }),
        loader: () => import('@skunexus/parcel-form'),
    },
});
