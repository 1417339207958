import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { AvForm } from 'availity-reactstrap-validation';

import { FormItem, Modal } from '@skunexus/component-catalog';

const ExportModal = ({ warehouses, isLoading, fetchWarehouses, modalData, closeModal, exportStock }) => {
    const formRef = useRef(null);
    const [formIsLoading, setFormIsLoading] = useState(false);

    useEffect(() => {
        if (warehouses.length === 0 && modalData.isOpen) {
            fetchWarehouses();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modalData.isOpen]);

    const handleConfirmForm = () => {
        formRef.current.submit();
    };

    const handleSubmit = (e, { warehouse_id }) => {
        setFormIsLoading(true);
        exportStock({
            data: {
                id: warehouse_id,
            },
            onFulfill: () => {
                setFormIsLoading(false);
            },
        });
    };

    return (
        <Modal
            headerLabel='Export Stock'
            isOpen={modalData.isOpen}
            isLoading={isLoading}
            confirmOnClick={handleConfirmForm}
            confirmLabel='Save'
            footerProps={{
                confirmProps: {
                    disabled: formIsLoading,
                },
                cancelProps: {
                    disabled: formIsLoading,
                },
            }}
            cancelOnClick={closeModal}>
            <AvForm ref={formRef} onValidSubmit={handleSubmit}>
                <FormItem
                    placeholder='Select Warehouse'
                    label={'Warehouse'}
                    name='warehouse_id'
                    type='dropdown'
                    options={warehouses}
                    value={''}
                    validate={{ required: { value: true } }}
                />
            </AvForm>
        </Modal>
    );
};

ExportModal.propTypes = {
    warehouses: PropTypes.array,
    isLoading: PropTypes.bool,
    modalData: PropTypes.shape({
        isOpen: PropTypes.bool,
    }),
    fetchWarehouses: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
    exportStock: PropTypes.func.isRequired,
};
ExportModal.defaultProps = {
    isLoading: false,
    warehouses: [],
    modalData: {
        isOpen: false,
    },
};

export default ExportModal;
