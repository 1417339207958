import routes from './routes';
import { TITLE_PREFIX, INDEX_ROUTE } from './consts';

export default (app) => {
    app.settingsTabs.addItem('integrations', {
        label: 'Integrations',
        title: TITLE_PREFIX,
        link: INDEX_ROUTE,
        permission: 'connectors.index',
        order: 20,
    });

    app.routes.addMany(routes(app));
};
