import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import Component from './ProductsGridWrapper';

import actions from '../../core/actions';

const mapStateToProps = createStructuredSelector({});

const mapDispatchToProps = {
    openModal: actions.exportModal.openModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(Component);
