//PAGE TITLE
export const TITLE_PREFIX = 'Integrations';

//SHIP ROUTES
export const ROUTE_PREFIX = 'settings/integrations';
export const INDEX_ROUTE = `/${ROUTE_PREFIX}`;
export const DETAILS_PATH_ROUTE = `/${ROUTE_PREFIX}/:id`;
export const DETAILS_ROUTE = `/${ROUTE_PREFIX}`;
export const FORM_PATH_ROUTE = `/${ROUTE_PREFIX}/:action(add|edit)/:id?`;
export const CREATE_FORM_ROUTE = `/${ROUTE_PREFIX}/add`;
export const EDIT_FORM_ROUTE = `/${ROUTE_PREFIX}/edit`;
