import React from 'react';
import { ParentSpaceProvider, SubspaceProvider } from 'react-redux-subspace';

import { reducer, sagas, selectors } from './packages/ExportStock/core';
import ProductsGridWrapper from './packages/ExportStock/components/ProductsGridWrapper';

export default {
    name: 'exportProductStock',
    before: [],
    after: [],
    setup: (app) => {
        const products = app.routes.getItem('products');

        if (products) {
            app.reducers.addItem('exportProductStock', reducer);
            app.sagas.addItem('exportProductStock', sagas);
            app.selectors.addItem('exportProductStock', selectors);

            products.render = (Component) => (props) => (
                <ParentSpaceProvider>
                    <SubspaceProvider
                        {...props}
                        mapState={(state) =>
                            state.products
                                ? {
                                      exportProductStock: state.exportProductStock,
                                      ...state.products,
                                  }
                                : state
                        }>
                        <ProductsGridWrapper {...props}>
                            <Component />
                        </ProductsGridWrapper>
                    </SubspaceProvider>
                </ParentSpaceProvider>
            );

            app.routes.changeItem('products', products);
        }
    },
};
