import get from 'lodash/get';
import values from 'lodash/values';
import createConfigurableObject from '../../../utils/configurationObject';
import { firstAllowedLink, combinePermissions } from '../../../utils';

import routes from './routes';
import settingsRoutes from './settingsRoutes';
import sideMenuItem from './sideMenu';
import settingsTabs from './settingsTabs';

export default (app) => {
    app.settingsOrderTabs = app.settingsOrderTabs || createConfigurableObject();
    app.settingsOrderTabs.addMany(settingsTabs(app));
    app.settingsTabs.addItem('orders', {
        label: 'Orders',
        link: () => firstAllowedLink(values(app.settingsOrderTabs.getAll())),
        permission: () => combinePermissions(values(app.settingsOrderTabs.getAll())),
        order: 11,
    });
    app.routes.addMany(settingsRoutes(app));

    app.routes.addMany(routes(app));
    app.sideMenuItems.addItem('orders', sideMenuItem(app));
};
