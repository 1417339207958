import { combineReducers } from 'redux';
import { handleActions } from 'redux-actions';

import { openModal, closeModal } from './actions';

const initState = {
    isOpen: false,
};

const data = handleActions(
    {
        [openModal]: (state, { payload }) => ({
            ...state,
            isOpen: true,
        }),
        [closeModal]: () => initState,
    },
    initState,
);

export default combineReducers({
    data,
});
