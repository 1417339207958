import values from 'lodash/values';
import { withTabs } from '@skunexus/component-catalog';
import { filterAccessibleItems } from '../../../utils';

import {
    TITLE_PREFIX,
    INDEX_ROUTE,
    DETAILS_PATH_ROUTE,
    DETAILS_ROUTE,
    FORM_PATH_ROUTE,
    CREATE_FORM_ROUTE,
    EDIT_FORM_ROUTE,
} from './consts';

export default (app) => ({
    integrations: {
        path: '/integrations',
        title: 'Integrations',
        permission: ['integrations.index'],
        loader: () => import('@skunexus/integrations'),
        props: () => ({
            detailsPageRoute: '/integrations',
        }),
    },
    settingIntegrations: {
        path: app.settingsTabs.getItem('integrations').link,
        title: app.settingsTabs.getItem('integrations').title,
        permission: app.settingsTabs.getItem('integrations').permission,
        props: () => ({
            detailsPageRoute: DETAILS_ROUTE,
            addFormPageRoute: CREATE_FORM_ROUTE,
            updateFormPageRoute: EDIT_FORM_ROUTE,
        }),
        loader: () => import('@skunexus/integrations'),
        render: (Component) => withTabs(filterAccessibleItems(values(app.settingsTabs.getAll())))(Component),
    },
    settingIntegrationForm: {
        path: FORM_PATH_ROUTE,
        title: TITLE_PREFIX,
        permission: 'connectors.index',
        props: () => ({
            gridPageRoute: INDEX_ROUTE,
        }),
        loader: () => import('@skunexus/integration-form'),
    },
    settingIntegrationDetails: {
        path: DETAILS_PATH_ROUTE,
        title: TITLE_PREFIX,
        permission: 'connectors.index',
        props: () => ({
            gridPageRoute: INDEX_ROUTE,
            updateFormPageRoute: EDIT_FORM_ROUTE,
        }),
        loader: () => import('@skunexus/integration-details'),
    },
});
