import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import ExportStockModal from '../ExportStockModal';

const ProductsGridWrapper = ({ children, openModal, ...restProps }) => {
    
    const handleExportStock = () => {
        openModal();
    };

    restProps.customGridData = (data) => {
        data.config.headerActions.push({
            id: 'export-stock',
            label: 'Export Stock',
            handler: handleExportStock,
        });
        return data;
    };

    return (
        <Fragment>
            <ExportStockModal />
            {React.cloneElement(children, { ...restProps })}
        </Fragment>
    );
};

ProductsGridWrapper.propTypes = {
    openModal: PropTypes.func.isRequired,
};
ProductsGridWrapper.defaultProps = {};

export default ProductsGridWrapper;
