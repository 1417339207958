import set from 'lodash/set';
import findIndex from 'lodash/findIndex';

const customFulfillmentAll = (app) => {
    const customGridData = (gridProps) => {
        const orderColIndex = findIndex(gridProps.columns, ({ label }) => label === 'Order');
        if (orderColIndex >= 0) {
            set(gridProps.columns[orderColIndex], 'field', 'relatedOrder.id');
        }

        gridProps.columns.splice(5, 0, {
            field: 'relatedOrder.dispatch_date',
            label: 'Dispatch Date',
            type: 'date',
            isFilterable: true,
            isSortable: true,
        });

        return gridProps;
    };

    const routeName = 'fulfillmentAll';
    const route = app.routes.getItem(routeName);
    if (route) {
        const routeProps = route.props;

        route.props = (state) => ({
            ...routeProps(state),
            customGridData,
        });
        app.routes.changeItem(routeName, route);
    }
};

const customFulfillmentBatching = (app) => {
    const customGridData = (gridProps) => {
        set(gridProps, 'config.isLoading', false);
        set(gridProps, 'config.adjustHeight', false);
        set(gridProps, 'refreshConfig.initInterval', 5000);
        return gridProps;
    };

    const customPickListData = (gridProps) => {
        set(gridProps, 'config.isLoading', false);
        set(gridProps, 'config.adjustHeight', false);
        return gridProps;
    };
    const routeName = 'fulfillmentShipBatching';
    const route = app.routes.getItem(routeName);
    if (route) {
        const routeProps = route.props;

        route.props = (state) => ({
            ...routeProps(state),
            customGridData,
            customPickListData,
        });
        app.routes.changeItem(routeName, route);
    }
};

export default {
    name: 'customFulfillments',
    before: [],
    after: [],
    setup: (app) => {
        customFulfillmentAll(app);
        customFulfillmentBatching(app);
    },
};
