import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import Component from './ExportStockModal';

import actions from '../../core/actions';
import { getWarehousesRows, getWarehousesIsLoading, getExportModalData } from '../../core/selectors';

const mapStateToProps = createStructuredSelector({
    modalData: getExportModalData,
    warehouses: getWarehousesRows,
    isLoading: getWarehousesIsLoading
});

const mapDispatchToProps = {
    exportStock: actions.exportStock.TRIGGER,
    closeModal: actions.exportModal.closeModal,
    fetchWarehouses: actions.warehouses.fetchRows.TRIGGER,
};

export default connect(mapStateToProps, mapDispatchToProps)(Component);
