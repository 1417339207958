export default (app) => ({
    orderDecisionSettings: {
        id: 'orderDecisionSettings',
        label: 'Order Decision Settings',
        title: 'Order Decision Settings',
        link: '/settings/order/decision',
        permission: [],
        order: 10,
    },
    orderDecisionRuleSettings: {
        id: 'orderDecisionRuleSettings',
        label: 'Order Decision Rules Settings',
        title: 'Order Decision Rules Settings',
        link: '/settings/order/rules',
        permission: [],
        order: 20,
    },
});
