import React, { Fragment } from 'react';
import { ParentSpaceProvider, SubspaceProvider } from 'react-redux-subspace';
import values from 'lodash/values';
import get from 'lodash/get';
import MainTabs from '@skunexus/fulfillment-main-tabs';

import app from '../../../../config';
import { filterAccessibleItems } from '../../../../utils';

const MainTabsLayout = ({ children, name, ...props }) => {
    const mainTabsParamsConfig = get(app.general.getItem('modules'), 'fulfillment.mainTabsParamsConfig', {});

    const states = () => {
        const tabs = app.receiveInventoryTabs.getAll();
        return values(tabs);
    };
    return (
        <div>
            <ParentSpaceProvider>
                <SubspaceProvider
                    mapState={(state) => ({
                        ...state.fulfillmentMainTabs,
                        ...state[name],
                    })}
                >
                    <Fragment>
                        <MainTabs states={filterAccessibleItems(states)} {...mainTabsParamsConfig} {...props} />
                        {children}
                    </Fragment>
                </SubspaceProvider>
            </ParentSpaceProvider>
        </div>
    );
};

export default MainTabsLayout;
