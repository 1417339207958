import React, { Fragment } from 'react';
import values from 'lodash/values';
import get from 'lodash/get';
import { ParentSpaceProvider, SubspaceProvider } from 'react-redux-subspace';
import MainTabs from '@skunexus/fulfillment-main-tabs';

import { filterAccessibleItems } from '../../../../utils';
import app from '../../../../config';

const MainTabsLayout = ({ children, name, ...props }) => {
    const mainTabsParamsConfig = get(app.general.getItem('modules'), 'fulfillment.mainTabsParamsConfig', {});

    const states = () => {
        const pickupFulfillmentTabs = app.pickupFulfillmentTabs.getAll();

        if (pickupFulfillmentTabs.pick) {
            pickupFulfillmentTabs.pick.config = {
                countable: true,
                countGraphqlFilterPart: 'current_state: {operator: eq, value: "pick"}',
            };
        }

        if (pickupFulfillmentTabs.pack) {
            pickupFulfillmentTabs.pack.config = {
                countable: true,
                countGraphqlFilterPart: 'current_state: {operator: eq, value: "pack"}',
            };
        }

        if (pickupFulfillmentTabs.groupPick) {
            pickupFulfillmentTabs.groupPick.config = {
                countable: true,
                countGraphqlFilterPart:
                    'type: { operator: like, value: "%manual%" }, state: { operator: neq, value: "picked" }',
                gridField: 'groupGrid',
            };
        }

        if (pickupFulfillmentTabs.waves) {
            pickupFulfillmentTabs.waves.config = {
                countable: true,
                countGraphqlFilterPart:
                    'type: { operator: like, value: "%wave%" }, state: { operator: neq, value: "picked" }',
                gridField: 'groupGrid',
            };
        }

        return values(app.pickupFulfillmentTabs.getAll());
    };

    return (
        <div>
            <ParentSpaceProvider>
                <SubspaceProvider
                    mapState={(state) => ({
                        ...state.fulfillmentMainTabs,
                        ...state[name],
                    })}
                >
                    <Fragment>
                        <MainTabs
                            states={filterAccessibleItems(states)}
                            domain="inStoreFulfillment"
                            {...mainTabsParamsConfig}
                            {...props}
                        />
                        {children}
                    </Fragment>
                </SubspaceProvider>
            </ParentSpaceProvider>
        </div>
    );
};

export default MainTabsLayout;
