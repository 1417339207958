import { all, call, put, takeEvery } from 'redux-saga/effects';

import { actions as notification } from '@skunexus/notification';
import { ajaxExceptionMessage } from '@skunexus/utils';
import get from 'lodash/get';

import * as API from '../api';
import actions from './actions';
import warehousesSagas from './warehouses/sagas';

function* notifyAboutFailure(action) {
    yield put(notification.error(action.payload));
}

function* onExportStock({ payload }) {
    try {
        yield put(actions.exportStock.REQUEST(payload));

        const response = yield call(API.exportStock, payload);
        const status = get(response, 'status', null);

        if (status !== 200) {
            throw new Error('Something went wrong with stock export');
        }

        yield put(actions.exportStock.SUCCESS(status));
    } catch (e) {
        yield put(actions.exportStock.FAILURE(ajaxExceptionMessage(e)));
    } finally {
        if (payload.onFulfill) {
            payload.onFulfill();
        }
        yield put(actions.exportStock.FULFILL());
    }
}

function* onCloseModal() {
    yield put(actions.exportModal.closeModal());
}

export default function* preferredWarehousesModuleSagas() {
    yield all([
        takeEvery(actions.warehouses.fetchRows.FAILURE, notifyAboutFailure),
        takeEvery(actions.exportStock.TRIGGER, onExportStock),
        takeEvery(actions.exportStock.FAILURE, notifyAboutFailure),
        takeEvery(actions.exportStock.SUCCESS, onCloseModal),
        warehousesSagas(),
    ]);
}
