import { sagas as fromAuth } from '@skunexus/auth';
import { sagas as fromFulfillmentMainTabs } from '@skunexus/fulfillment-main-tabs';
import { sagas as vendorNotificationSagas } from '@skunexus/vendor-notifications';
import { sagas as customFieldsSagas } from '@skunexus/custom-fields';
import { sagas as LocationFormComponentSagas } from '@skunexus/location-form-components';

import createConfigurableObject from '../../../utils/configurationObject';

export default (app) => {
    app.sagas = app.sagas || createConfigurableObject();
    app.sagas.addMany({
        fromAuth,
        fromFulfillmentMainTabs,
        vendorNotificationSagas,
        customFieldsSagas,
        LocationFormComponentSagas,
    });
};
