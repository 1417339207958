import values from 'lodash/values';
import { withTabs } from '@skunexus/component-catalog';
import { filterAccessibleItems } from '../../../utils';

export const PREFFERED_WAREHOUSES_ROUTE = '/settings/warehouses/preferred';

export default {
    name: 'preferredWarehouses',
    before: [],
    after: [],
    setup: (app) => {
        const route = PREFFERED_WAREHOUSES_ROUTE;
        const label = 'Preferred Warehouses';
        const permission = 'warehouses.index';

        app.settingsWarehousesTabs.addItem('preferredWarehouses', {
            label: label,
            link: route,
            permission: permission,
            order: 15,
        });

        app.routes.addItem('preferredWarehousesRoute', {
            path: route,
            title: label,
            permission: permission,
            props: () => ({}),
            loader: () => import('./packages/PreferredWarehouses/core'),
            render: (Component) =>
                withTabs(filterAccessibleItems(values(app.settingsTabs.getAll())))(
                    withTabs(filterAccessibleItems(values(app.settingsWarehousesTabs.getAll())), { sub: true })(
                        Component
                    )
                ),
        });
    },
};
