import { combineReducers } from 'redux';
import { handleActions } from 'redux-actions';

import { fetchRows } from './actions';

// Rows
const rows = handleActions(
    {
        [fetchRows.SUCCESS]: (state, { payload: { rows = [] } = {} }) => rows
    },
    []
);

// Loading
const loading = handleActions(
    {
        [fetchRows.REQUEST]: () => true,
        [fetchRows.FULFILL]: () => false,
    },
    false
);

export default combineReducers({
    loading,
    rows,
});
