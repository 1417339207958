import React from 'react';
import { Button } from 'reactstrap';
import values from 'lodash/values';
import { withTabs } from '@skunexus/component-catalog';
import { filterAccessibleItems } from '../../../utils';
import get from 'lodash/get';
import set from 'lodash/set';

export default {
    name: 'customProductStockStatuses',
    before: [],
    after: [],
    setup: (app) => {
        const productDetailsOverview = app.routes.getItem('productDetailsOverview');

        if (productDetailsOverview) {
            const mapStateToProps = (props) => {
                const newProps = { ...props };
                const onHand = {
                    ...get(newProps, 'config.statuses.onHand', {}),
                    label: (
                        <Button
                            color='link'
                            style={{
                                fontSize: '11px',
                                textTransform: 'uppercase',
                                border: 'none',
                            }}
                            onClick={() => {
                                newProps.history.push(`/products/stock/${newProps.params.id}`);
                            }}>
                            On Hand
                        </Button>
                    ),
                };
                set(newProps, 'config.statuses.onHand', onHand);
                return newProps;
            };

            productDetailsOverview.render = (Component) =>
                withTabs(filterAccessibleItems(values(app.productDetailsTabs.getAll())))((props) => (
                    <Component {...mapStateToProps(props)} />
                ));
            app.routes.changeItem('productDetailsOverview', productDetailsOverview);
        }
    },
};
