import React, { Fragment } from 'react';
import { ParentSpaceProvider, SubspaceProvider } from 'react-redux-subspace';
import values from 'lodash/values';
import get from 'lodash/get';
import MainTabs from '@skunexus/fulfillment-main-tabs';

import app from '../../../../config';
import { firstAllowedLink, filterAccessibleItems, combinePermissions } from '../../../../utils';

const MainTabsLayout = ({ children, name, ...props }) => {
    const mainTabsParamsConfig = get(app.general.getItem('modules'), 'fulfillment.mainTabsParamsConfig', {});

    const states = () => {
        const fulfillmentShipTabs = app.fulfillmentShipTabs.getAll();

        if (!!fulfillmentShipTabs.waves) {
            const fulfillmentWavesTabs = values(app.fulfillmentWavesTabs.getAll());

            fulfillmentShipTabs.waves.link = firstAllowedLink(fulfillmentWavesTabs);
            fulfillmentShipTabs.waves.permission = combinePermissions(fulfillmentWavesTabs);
        }

        if (fulfillmentShipTabs.pick) {
            fulfillmentShipTabs.pick.config = {
                countable: true,
                countGraphqlFilterPart: 'current_state: {operator: eq, value: "pick"}',
            };
        }

        if (fulfillmentShipTabs.pack) {
            fulfillmentShipTabs.pack.config = {
                countable: true,
                countGraphqlFilterPart: 'current_state: {operator: eq, value: "pack"}',
            };
        }

        if (fulfillmentShipTabs.groupPick) {
            fulfillmentShipTabs.groupPick.config = {
                countable: true,
                countGraphqlFilterPart:
                    'type: { operator: like, value: "%manual%" }, state: { operator: neq, value: "picked" }',
                gridField: 'groupGrid',
            };
        }

        if (fulfillmentShipTabs.waves) {
            fulfillmentShipTabs.waves.config = {
                countable: true,
                countGraphqlFilterPart:
                    'type: { operator: like, value: "%wave%" }, state: { operator: neq, value: "picked" }',
                gridField: 'groupGrid',
            };
        }

        return values(fulfillmentShipTabs);
    };

    return (
        <div>
            <ParentSpaceProvider>
                <SubspaceProvider
                    mapState={(state) => ({
                        ...state.fulfillmentMainTabs,
                        ...state[name],
                    })}
                >
                    <Fragment>
                        <MainTabs states={filterAccessibleItems(states)} {...mainTabsParamsConfig} {...props} />
                        {children}
                    </Fragment>
                </SubspaceProvider>
            </ParentSpaceProvider>
        </div>
    );
};

export default MainTabsLayout;
