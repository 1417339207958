import React, { Fragment, useCallback } from 'react';
import PropTypes from 'prop-types';

import map from 'lodash/map';
import get from 'lodash/get';
import reduce from 'lodash/reduce';

import styles from '../../styles.module.css';

const Items = ({ details }) => {
    const getItems = useCallback(() => {
        return get(details, 'items', []);
    }, [details]);

    const allQty = reduce(getItems(), (result, item) => result + get(item, 'quantity', 0), 0);

    return (
        <Fragment>
            <table className={styles.table}>
                <thead>
                    <tr>
                        <th>Qty</th>
                        <th>Item</th>
                        <th>Description</th>
                    </tr>
                </thead>
                <tbody>
                    {map(getItems(), (item, index) => (
                        <tr key={index}>
                            <td>{get(item, 'quantity', 0)}</td>
                            <td>
                                <strong>{get(item, 'product.sku', '')}</strong>
                            </td>
                            <td>{get(item, 'product.name', '')}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <div className={styles.itemsSummary}>
                <strong>{allQty} total qty in shipment</strong>
            </div>
        </Fragment>
    );
};

Items.propTypes = {
    details: PropTypes.object,
};

Items.defaultProps = {
    details: {},
};

export default Items;
