import React from 'react';

import MainTabs from './components/ReceiveInventoryMainTabsLayout';

export default () => {
    const routers = {
        receiveInventoryEnterLocationDetails: {
            path: '/receive-inventory-enter-location/:id/:stockId',
            title: 'Receive Inventory - Enter Location Details',
            permission: ['purchase orders - stocks.receive'],
            props: () => ({
                indexPageRoute: '/receive-inventory-main',
                descriptorTypes: {
                    1: 'string',
                    2: 'number',
                    3: 'select',
                },
            }),
            loader: () => import('@skunexus/ri-enter-location-details'),
        },
        receiveInventoryJai: {
            path: '/receive-inventory-just-adding',
            title: 'Receive Inventory - Just Adding Inventory',
            permission: ['inventory - stocks.create', 'inventory - stocks.adjust'],
            props: () => ({
                indexPageRoute: '/receive-inventory/main',
                productDetailsPageRoute: '/products/overview',
            }),
            loader: () => import('@skunexus/receive-inventory-just-adding'),
        },
        receiveInventoryMain: {
            path: '/receive-inventory/main',
            title: 'Receive Inventory',
            permission: ['purchase orders - stocks.receive'],
            props: () => ({
                productDetailsPageRoute: '/products/overview',
                formPageRoute: '/receive-inventory-enter-location',
                jaiRoute: '/receive-inventory-just-adding',
                receiveFromPoRoute: '/receive-inventory-purchase-order',
                itemsReceiveRoute: '/receive-inventory/items',
            }),
            loader: () => import('@skunexus/receive-inventory'),
            render: (Component) => (props) =>
                (
                    <MainTabs
                        showCartFilter={false}
                        showAssignUserFilter={false}
                        showWarehouseFilter={false}
                        {...props}
                        name="receiveInventoryMain"
                    >
                        <Component {...props} />
                    </MainTabs>
                ),
        },
        receiveInventoryItems: {
            path: '/receive-inventory/items',
            title: 'Items Received',
            permission: ['receive inventory.put away'],
            props: () => ({
                productDetailsPageRoute: '/products/overview',
                putAwayPageRoute: '/receive-inventory/put-away',
            }),
            loader: () => import('@skunexus/receive-inventory-items-received'),
            render: (Component) => (props) =>
                (
                    <MainTabs
                        showCartFilter={false}
                        showAssignUserFilter={false}
                        showWarehouseFilter={false}
                        {...props}
                        name="receiveInventoryItems"
                    >
                        <Component {...props} />
                    </MainTabs>
                ),
        },
        receiveInventoryPutAway: {
            path: '/receive-inventory/put-away',
            title: 'Put Away',
            permission: ['receive inventory.put away'],
            props: () => ({
                putAwayMainRoute: '/receive-inventory/put-away-main',
            }),
            loader: () => import('@skunexus/put-away'),
            render: (Component) => (props) =>
                (
                    <MainTabs {...props} name="receiveInventoryPutAway">
                        <Component {...props} />
                    </MainTabs>
                ),
        },
        receiveInventoryPutAwayMain: {
            path: '/receive-inventory/put-away-main/:id',
            title: 'Put Away',
            permission: ['receive inventory.put away'],
            props: () => ({
                finalizedRoute: '/receive-inventory-finalized',
                putAwayRoute: '/receive-inventory/put-away',
                putAwayFinalRoute: '/receive-inventory/put-away-final',
                productDetailsPageRoute: '/products/overview',
            }),
            loader: () => import('@skunexus/put-away-main'),
        },
        receiveInventoryPutAwayFinal: {
            path: '/receive-inventory/put-away-final',
            title: 'Put Away',
            permission: ['receive inventory.put away'],
            props: () => ({
                detailsPageRoute: '/receive-inventory/put-away-final-details',
            }),
            loader: () => import('@skunexus/put-away-final'),
            render: (Component) => (props) =>
                (
                    <MainTabs showCartFilter={false} {...props} name="receiveInventoryPutAwayFinal">
                        <Component {...props} />
                    </MainTabs>
                ),
        },
        receiveInventoryPutAwayFinalDetails: {
            path: '/receive-inventory/put-away-final-details/:id',
            title: 'Put Away Details',
            permission: ['receive inventory.put away'],
            loader: () => import('@skunexus/put-away-final-details'),
        },
    };

    return routers;
};
